import { Control, UseFormSetValue } from "react-hook-form";
import EditableCell from "../../../components/EditTable/EditableCell/EditableCell";
import { ClinicalProps } from "./ClinicalRow";
import { CapScan, KitResponse } from "../../../models/responses/kit.response";
import { getFormattedLocalDate } from "../../../util/date.util";
import { useEffect, useMemo } from "react";
import { KitSampleDto } from "../../../api";
import { SampleType } from "../../../models/responses/sampleReports.response";

type OtherSamplesSubTableProps = {
  setValue: UseFormSetValue<ClinicalProps>;
  kitItem: KitResponse;
  capscans: CapScan[];
  control: Control<ClinicalProps, any>;
  refetch: () => void;
  sampleType: SampleType;
};

export const OtherSamplesSubTable = ({
  kitItem,
  capscans,
  control,
  refetch,
  setValue,
  sampleType,
}: OtherSamplesSubTableProps) => {
  const filteredSamples = kitItem.samples?.filter(
    (s) => Number(s.sampleType) === sampleType
  );

  const samples = useMemo(() => {
    return capscans.map((_, index) => {
      if (filteredSamples && filteredSamples[index]) {
        return { ...filteredSamples[index], sampleType: Number(sampleType) };
      }
      return {} as KitSampleDto;
    });
  }, [capscans]);
  useEffect(() => {
    samples.forEach((s, index) => {
      if (sampleType === SampleType.plasma) {
        setValue(`plasmaSamples.${index}.sampleType`, sampleType, {
          shouldDirty: true,
        });
        setValue(`plasmaSamples.${index}.id`, s.sampleId, {
          shouldDirty: true,
        });
        setValue(
          `plasmaSamples.${index}.sampleNumber`,
          index + Number(kitItem.id),
          { shouldDirty: true }
        );
      } else {
        setValue(`salivaSamples.${index}.sampleType`, sampleType, {
          shouldDirty: true,
        });
        setValue(`salivaSamples.${index}.id`, s.sampleId, {
          shouldDirty: true,
        });
        setValue(
          `salivaSamples.${index}.sampleNumber`,
          index + Number(kitItem.id),
          { shouldDirty: true }
        );
      }
    });
  }, [kitItem, sampleType, samples, setValue]);
  return (
    <>
      <td width={"10%"}>
        <>
          {samples.map((sample, index) => (
            <>
              <EditableCell
                key={`${sampleType}_${index}.processCollectionDate`}
                defaultValue={
                  sample.processCollectionDate &&
                  getFormattedLocalDate(
                    sample.processCollectionDate,
                    "YYYY-MM-DD"
                  )
                }
                type="date"
                name={
                  sampleType === SampleType.plasma
                    ? `plasmaSamples.${index}.processCollectionDate`
                    : `salivaSamples.${index}.processCollectionDate`
                }
                control={control}
              />
            </>
          ))}
        </>
      </td>
      <td width={"10%"}>
        <>
          {samples.map((sample, index) => (
            <>
              <EditableCell
                key={`${sampleType}_${index}.processCollectionDateTime`}
                defaultValue={
                  sample.processCollectionDate &&
                  getFormattedLocalDate(sample.processCollectionDate, "HH:mm")
                }
                type="time"
                name={
                  sampleType === SampleType.plasma
                    ? `plasmaSamples.${index}.processCollectionDateTime`
                    : `salivaSamples.${index}.processCollectionDateTime`
                }
                control={control}
              />
            </>
          ))}
        </>
      </td>
      <td width={"10%"}>
        <>
          {samples.map((sample, index) => (
            <>
              <EditableCell
                key={`${sampleType}_${index}.storageDate`}
                defaultValue={
                  sample.storageDate &&
                  getFormattedLocalDate(sample.storageDate, "YYYY-MM-DD")
                }
                type="date"
                name={
                  sampleType === SampleType.plasma
                    ? `plasmaSamples.${index}.storageDate`
                    : `salivaSamples.${index}.storageDate`
                }
                control={control}
              />
            </>
          ))}
        </>
      </td>
      <td width={"10%"}>
        <>
          {samples.map((sample, index) => (
            <>
              <EditableCell
                key={`${sampleType}_${index}.storageTime`}
                defaultValue={
                  sample.storageDate &&
                  getFormattedLocalDate(sample.storageDate, "HH:mm")
                }
                type="time"
                name={
                  sampleType === SampleType.plasma
                    ? `plasmaSamples.${index}.storageTime`
                    : `salivaSamples.${index}.storageTime`
                }
                control={control}
              />
            </>
          ))}
        </>
      </td>
      <td width={"10%"}>
        <>
          {samples.map((sample, index) => (
            <>
              <EditableCell
                key={`${sampleType}_${index}.temperature`}
                defaultValue={sample.temperature}
                type="number"
                name={
                  sampleType === SampleType.plasma
                    ? `plasmaSamples.${index}.temperature`
                    : `salivaSamples.${index}.temperature`
                }
                control={control}
                min={-80}
                max={-20}
              />
            </>
          ))}
        </>
      </td>
    </>
  );
};
