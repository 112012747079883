import { useQuery } from "@tanstack/react-query";
import { kitsByStudyDepartmentQuery } from "../../queries/kit.query";
import { Table as BsTable } from "react-bootstrap";
import { KitResponse } from "../../models/responses/kit.response";
import useTableHelper from "../../hooks/useTableHelper";
import { LoadingAndErrorHandler } from "../../components/Table/Table";
import { useLocation } from "react-router-dom";
import ClinicalRow from "./ClinicalRow/ClinicalRow";
import styles from "./ClinicalTable.module.css";
import {
  generateCapscanHeaders,
  generateHeaders,
  generateSampleHeaders,
} from "./ClinicalCenterUtil";

const ClinicalTable = () => {
  const {
    state: { studyDepartmentId, studyName },
  } = useLocation();

  const {
    data: kits,
    refetch,
    isError,
    isLoading,
  } = useQuery(kitsByStudyDepartmentQuery({ studyDepartmentId }));

  const { getTitle } = useTableHelper();

  const TableContent = () => {
    return (
      <tbody>
        {kits?.data?.map((item: KitResponse) => (
          <ClinicalRow
            item={item}
            key={item.id}
            studyName={studyName}
            refetch={refetch}
          />
        ))}
      </tbody>
    );
  };

  return (
    <div className={`${styles["table-container"]} m-auto`}>
      <BsTable className={`table table-bordered m-auto ${styles.clinical}`}>
        <thead>
          <tr>{generateHeaders(getTitle)}</tr>
          <tr>
            {generateCapscanHeaders(getTitle)}
            {generateSampleHeaders(getTitle)}
            {generateSampleHeaders(getTitle)}
          </tr>
        </thead>
        {kits ? (
          <TableContent />
        ) : (
          <LoadingAndErrorHandler isError={isError} isLoading={isLoading} />
        )}
      </BsTable>
    </div>
  );
};

export default ClinicalTable;
