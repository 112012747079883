import { ReactNode, useState, useEffect } from "react";
import { BaseIcon } from "../../BaseIcon/BaseIcon";
import styles from "./EditableCell.module.css";
import EditIcon from "../../../assets/edit.svg";
import { FieldValues, UseControllerProps } from "react-hook-form";
import FormInput from "../../form/FormInput/FormInput";
export type CellType = "date" | "time" | "modal" | "text" | "number";

type EditableCellProps = {
  className?: string;
  name?: string;
  type?: CellType;
  required?: boolean;
  min?: number;
  max?: number;
  renderModal?: (setShow: (show: boolean) => void) => ReactNode;
  icon?: string;
};

export default function EditableCell<T extends FieldValues>({
  type,
  className,
  name,
  required,
  defaultValue,
  min,
  max,
  rules,
  icon,
  renderModal,
  ...formProps
}: EditableCellProps & UseControllerProps<T>) {
  const [editMode, setEditMode] = useState(false);
  useEffect(() => {
    setEditMode(false);
  }, [defaultValue]);

  const onClick = () => {
    !editMode && setEditMode(true);
  };

  const CustomInput = () => {
    if (
      type === "text" ||
      type === "date" ||
      type === "time" ||
      type === "number"
    ) {
      return (
        <FormInput
          type={"text"}
          defaultValue={defaultValue}
          name={name}
          rules={{ ...rules, required: required, min: min, max: max }}
          input={{
            type: type,
            className: className,
            min: min,
            max: max,
          }}
          {...formProps}
        />
      );
    }
    return <></>;
  };

  return (
    <div className={"d-flex justify-content-between w-100 " + className}>
      {editMode ? CustomInput() : defaultValue}
      {!editMode && (
        <BaseIcon
          icon={icon ?? EditIcon}
          className={`${styles.editIcon} ms-auto`}
          onClick={onClick}
        />
      )}
      {type === "modal" && editMode && renderModal && renderModal(setEditMode)}
    </div>
  );
}
